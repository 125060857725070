<div class="relation-card" #relationCard>
  <div class="photo-section">
    <a
      [routerLink]="[relationPath, relation.target_id]"
      [state]="{ id: relation.target_id }"
    >
      <img
        src="{{
          relationImgUlr
            ? relationImgUlr
            : 'assets/img/gender-' + gender + '.svg'
        }}"
        alt="Photo"
        [ngClass]="[
          'gender-' + gender,
          !relationImgUlr ? 'no-profile-photo' : 'profile-photo'
        ]"
      />
    </a>
  </div>
  <div class="info-section">
    <div class="info-section-header">
      <div>
        <div #fullname class="fullname">
          <a
            [routerLink]="[relationPath, relation.target_id]"
            [state]="{ id: relation.target_id }"
            >{{ localizedFullname }}</a
          >
        </div>
      </div>
      <div class="bh-data">
        <div
          class="bh-theme small-regular-text mr-1"
          [ngStyle]="{ backgroundColor: categoryColor(relation?.bh_theme) }"
        >
          {{ 'theme.' + relation?.bh_theme | translate }}
        </div>
        <app-map-pin
          *ngIf="relation?.bh_location?.floor || relation?.bh_floor"
          [floor]="relation?.bh_location?.floor || relation?.bh_floor"
          [targetId]="relation?.target_id"
        ></app-map-pin>
      </div>
    </div>
    <div class="brief-info">
      <div class="small-regular-text">{{ livingDates }}</div>
      <div>
        <span class="small-text" translate>home.relationCard.num</span>
        <span class="small-regular-text">{{ this.relation.step_count }}</span>
      </div>
    </div>
  </div>
</div>
