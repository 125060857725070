<div
  [ngClass]="[
    'connection',
    'connection-' + gender,
    direct ? 'direct' : 'indirect'
  ]"
>
  <div class="connection-badge" [ngClass]="{ expanded: expanded }">
    <div class="connection-photo">
      <a
        [routerLink]="clickable ? [profileUrl, connection.id] : []"
        [state]="clickable ? { id: connection.id } : undefined"
      >
        <img
          src="{{
            relationImgUlr
              ? relationImgUlr
              : 'assets/img/gender-' + gender + '.svg'
          }}"
          alt="Photo"
          [ngClass]="[
            'gender-' + gender,
            !relationImgUlr ? 'no-profile-photo' : 'profile-photo'
          ]"
        />
      </a>
    </div>
    <div class="info-section">
      <div
        class="name-section"
        [ngClass]="[
          direct ? 'subtitle-text' : 'regular-text',
          expanded ? 'info-section-upper' : ''
        ]"
      >
        <a
          [routerLink]="clickable ? [profileUrl, connection.id] : []"
          [state]="clickable ? { id: connection.id } : undefined"
        >
          {{ localizedFullname }}
        </a>
        <span *ngIf="!direct && hasNewMessages" class="new-message-count small-text" style="color:white" #popup>
          &nbsp; &nbsp; {{ countNewMessages }}
          <img src="assets/img/Mails.svg" alt="New messages" />
        </span>

      </div>
      <div *ngIf="relatedConnection && direct" class="small-text">
        {{ relation }}
      </div>
      <div *ngIf="expandable && !direct && expanded" class="small-text">
        {{ relation }}
        <button
          *ngIf="expanded"
          (click)="toggleExpandedHandler()"
          class="btn chevron"
        >
          <fa-icon [icon]="faAngleUp" size="sm"></fa-icon>
        </button>
      </div>
      <div *ngIf="!direct && expanded" class="brief-info">
        <div class="small-regular-text">{{ livingDates }}</div>
        <div>
          <span class="small-text" translate>home.relationCard.num</span>
          <span class="small-regular-text">{{ stepCount }}</span>
        </div>
      </div>
    </div>
    <button
      *ngIf="expandable && !direct && !expanded"
      (click)="toggleExpandedHandler()"
      class="btn chevron"
    >
      <fa-icon [icon]="faAngleDown" size="sm"></fa-icon>
    </button>
  </div>
  <div *ngIf="!direct && !expanded" class="relation small-text">
    {{ relation }}
    <app-gender-dot [gender]="gender" [inline]="false"></app-gender-dot>
  </div>

</div>
<div [ngClass]="direct ? 'direct' : 'indirect'">
  <div *ngIf="drawArrowDown" class="arrow-down"></div>
</div>
