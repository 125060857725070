<div class="toolbar" [ngClass]="{'external-content': externalContent}">
  <ng-content *ngIf="externalContent"></ng-content>
</div>

<ng-template #tools>
   <a [routerLink]="[settingsUrl, { action: 'sort' }]">
    <img src="assets/img/sort.svg" alt="" />
    <span class="small-text">Sort by</span>
  </a>
  <a [routerLink]="[settingsUrl, { action: 'search' }]">
    <img src="assets/img/search.svg" alt="" />
    <span class="small-text">Search</span>
  </a>
</ng-template>
