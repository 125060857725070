<app-title-bar [showGoToAllResultsText]="true" [showP2P]="true"> </app-title-bar>
<app-toolbar [externalContent]="true">
  <a
    *ngIf="relation.value; else loading"
    class="btn btn-lnk"
    [routerLink]="[profileUrl, relation.value?.target_id]"
  >
    {{ localizedFullname }}
  </a>
  <ng-template #loading>Loading...</ng-template>
</app-toolbar>
<div *ngIf="floor">
  <div class="location-info">
    <img src="assets/img/location.svg" />
    <span translate [translateParams]="{ value: floor }">map.locatedOn</span>
  </div>
  <div class="map-box" (click)="toggleMapZoom()" #mapBox>
    <div
      class="profile-pin"
      [ngStyle]="profilePhoto && location"
      [ngClass]="{ visible: profilePhoto && location }"
      #profilePin
    >
      <img src="assets/img/location.svg" />
      <div class="profile-pin-label">
        {{ labelText }}
      </div>
    </div>
    <img
      [src]="'/assets/img/maps/floor' + floor + '-' + locale + '.jpg'"
      alt="map"
      (load)="mapLoadedHandler()"
      #map
    />
    <div class="map-grid" #mapGrid></div>
  </div>
</div>
<app-spinner *ngIf="!relation.value" small="true"></app-spinner>
