<app-predefined-messages
  *ngIf="showPredefinedMessages"
  (predefinedMessageSelected)="onPredefineMessageSelected($event)"
></app-predefined-messages>
<ng-chat
  [ngClass]="{ hebrew: isHebrewLocale }"
  [adapter]="adapter"
  [userId]="userId"
  [hideFriendsList]="true"
  [isViewportOnMobileEnabled]="true"
  [browserNotificationsEnabled]="false"
  (onParticipantChatOpened)="onParticipantChatOpened($event)"
  (onMessagesSeen)="onMessagesSeen($event)"
  #ngChat
>
</ng-chat>
