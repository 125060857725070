<div class="app-modal">
  <div class="app-modal__content">
    <button class="btn app-modal__close-btn" (click)="closeModal.emit()">
      <img src="assets/img/x.svg" alt="x" />
    </button>
    <div *ngIf="activeUser; else relativesList">
      <div class="px-2">
        <app-connection
          [connection]="activeUser"
          [stepCount]="activeUser.step_count"
          [expandable]="false"
          [expanded]="true"
          [drawArrowDown]="false"
          [clickable]="false"
        >
        </app-connection>
      </div>
      <ul
        ngbNav
        #nav="ngbNav"
        [destroyOnHide]="false"
        [(activeId)]="activeTab"
        class="nav-tabs d-flex"
      >
        <li [ngbNavItem]="1" class="flex-grow-1">
          <a
            ngbNavLink
            class="border-0 h-100 d-flex flex-column justify-content-center align-items-center"
            translate
            (click)="acknowledgeSeenMessage()"
            ><span class="new-messages-count" [ngClass]="{ visible: hasNewMessages }" style="color:white">&nbsp;{{countNewMessages()}}</span>&nbsp; p2p.modal.tabs.chat</a
          >
          <ng-template ngbNavContent>
            <app-p2p-chat [activeChatUser]="activeChatUser"></app-p2p-chat>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="flex-grow-1">
          <a
            ngbNavLink
            class="border-0 h-100 d-flex flex-column justify-content-center align-items-center"
            translate
            >p2p.modal.tabs.profile</a
          >
          <ng-template ngbNavContent>
            <app-profile-details
              *ngIf="activeUserProfile"
              [profile]="activeUserProfile"
            >
            </app-profile-details>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="flex-grow-1">
          <a
            ngbNavLink
            class="border-0 h-100 d-flex flex-column justify-content-center align-items-center text-center"
            translate
            >p2p.modal.tabs.connection</a
          >
          <ng-template ngbNavContent>
            <app-connections-list
              *ngIf="activeUserConnections"
              [connections]="activeUserConnections"
            ></app-connections-list>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="p-2"></div>
    </div>
  </div>
</div>

<ng-template #relativesList>
  <div class="px-2" *ngIf="users">
    <h4>
      {{ 'p2p.modal.found.headline' | translate: { value: users?.length } }}
      <span translate>p2p.modal.found.click</span>
    </h4>
    <app-connection
      *ngFor="let user of users | relationsSortBy: sortOrder: limit; index as i"
      [connection]="user"
      [stepCount]="user?.step_count"
      [expandable]="false"
      [expanded]="true"
      [drawArrowDown]="false"
      [clickable]="false"
      (click)="setActiveUser(user)"
    ></app-connection>
  </div>
</ng-template>
