<app-title-bar [showGoToAllResultsText]="true" [showP2P]="true"> </app-title-bar>
<div *ngIf="relation">
  <app-info-bar *ngIf="user">
    <div class="small-text">
      <strong>{{ relation.target_profile.name }}</strong> is
      <span *ngIf="!connections">not related to </span
      ><strong>{{ user.name }}</strong>
      <span *ngIf="relationship"><strong>'s</strong> {{ relationship }}</span>
    </div>
  </app-info-bar>
  <app-connections-list [connections]="connections"></app-connections-list>
</div>
