<div class="profile">
  <div
    *ngIf="
      profile?.photo_urls?.large ||
      profile?.photo_urls?.medium as profilePhotoUrl
    "
    class="profile-photo"
    [ngStyle]="{ 'background-image': 'url(' + profilePhotoUrl + ')' }"
  ></div>
  <div class="profile-details">
    <div
      class="fullname-box"
      [ngClass]="{ 'hebrew-alignment': isHebrewLocale }"
    >
      <h4
        class="subtitle-text"
        [ngClass]="{ 'hebrew-alignment': isHebrewLocale }"
        [ngStyle]="{ 'margin-right': isHebrewLocale ? '80px' : 'unset' }"
      >
        {{ localizedFullname }}
      </h4>
      <app-map-pin
        *ngIf="relation?.bh_location?.floor || relation?.bh_floor"
        [floor]="relation?.bh_location?.floor || relation?.bh_floor"
        [targetId]="profile.id"
        [large]="true"
      ></app-map-pin>
    </div>
    <div class="birthdate" [ngClass]="{ 'hebrew-alignment': isHebrewLocale }">
      <span class="light-grey-text" translate>profile.birthdate</span>
      {{ profile?.birth?.date?.formatted_date }}
    </div>
    <div class="birthplace" [ngClass]="{ 'hebrew-alignment': isHebrewLocale }">
      <span class="light-grey-text" translate>profile.birthplace</span>
      {{ profile?.birth?.location?.formatted_location }}
    </div>
    <div *ngIf="convertMdToHtml(about) as aboutProfile" class="about">
      <span
        class="light-grey-text"
        [ngClass]="{ 'hebrew-alignment': isHebrewLocale }"
        translate
        [translateParams]="{ value: localizedFullname }"
        >profile.about</span
      >
      <div
        [ngClass]="{ 'hebrew-direction-deep': isHebrewLocale }"
        [innerHTML]="aboutProfile"
      ></div>
    </div>
    <a
      [href]="profile.profile_url"
      target="_blank"
      rel="nofollow noopener"
      [ngClass]="{ 'hebrew-direction': isHebrewLocale }"
      [innerHTML]="'profile.geni' | translate: { value: localizedFullname }"
    >
    </a>
  </div>
  <div
    class="read-more-footer"
    [ngClass]="{ 'hebrew-direction': isHebrewLocale }"
  >
    <img class="logo" src="assets/img/Anu-smalllogo.svg" alt="" />
    <a
      [href]="searchLink"
      target="_blank"
      rel="nofollow noopener"
      translate
      [translateParams]="{ value: localizedFullname }"
      >profile.footer<img class="link" src="assets/img/link.svg" alt="" />
    </a>
  </div>
</div>
