import { Pipe, PipeTransform } from '@angular/core';
import Path from '../model/Path';

const compareByProfileName = (a: Path, b: Path): number =>
  `${a.target_profile.last_name || a.target_profile.maiden_name}${
    a.target_profile.first_name || a.target_profile.name
  }` >
  `${b.target_profile.last_name || b.target_profile.maiden_name}${
    b.target_profile.first_name || b.target_profile.name
  }`
    ? 1
    : -1;

const compareByStepCount = (a: Path, b: Path): number =>
  a.step_count > b.step_count ? 1 : -1;

@Pipe({
  name: 'relationsSortBy',
  pure: false,
})
export class RelationsSortByPipe implements PipeTransform {
  constructor() {}

  transform(
    relations: Path[],
    order: RelationSortOrder = RelationSortOrder.DEFAULT,
    limit: number = 0
  ): Path[] {
    if (!relations.length) {
      return relations;
    }

    switch (order) {
      case RelationSortOrder.NAME:
        return [
          ...relations.sort((a: Path, b: Path) => compareByProfileName(a, b)),
        ];
      default:
        return [
          ...relations
            .filter((r) => r.step_count > 0)
            .sort((a: Path, b: Path) =>
              a.step_count === b.step_count
                ? compareByProfileName(a, b)
                : compareByStepCount(a, b)
            ),
          ...relations
            .filter((r) => r.step_count === 0)
            .sort((a: Path, b: Path) => compareByProfileName(a, b)),
        ].slice(0, limit);
    }
  }
}

export enum RelationSortOrder {
  DEFAULT,
  NAME,
  THEME,
  COUNTRY,
}
