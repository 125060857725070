<!-- TODO fix me -->
<!-- <app-modal></app-modal> -->
<main
  class="geni-container"
  [@routeChangeAnimation]="getRouteAnimationState(outlet)"
>
  <ng-template app-modal-ref></ng-template>
  <div [ngClass]="{ locked: isModalOpen }">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>
</main>
