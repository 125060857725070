<div class="connection-list" *ngIf="!loading; else spinner">
  <app-connection
    [connection]="userConnection"
    [direct]="true"
  ></app-connection>

  <div *ngIf="directConnectionsOnly; else allConnections">
    <div class="connections-dots">
      <div class="flex-box">
        <app-gender-dot
          *ngFor="let conn of connections.slice(1, connections.length - 1)"
          [gender]="identifyConnectionGender(conn)"
          [inline]="false"
        ></app-gender-dot>
      </div>
    </div>

    <div class="link-box">
      <button
        (click)="toggleDirectConnectionsOnly()"
        class="btn btn-link general-link subtitle-text"
        [innerHTML]="
          'relation.showAll' | translate: { value: connections.length - 1 }
        "
      ></button>
      <div class="arrow-down"></div>
    </div>
  </div>

  <app-connection
    [connection]="finalConnection"
    [direct]="true"
    [drawArrowDown]="false"
    [relatedConnection]="connections[connections.length - 2]"
  ></app-connection>
</div>

<ng-template #allConnections>
  <div
    *ngIf="connections && connections.slice(1, connections.length - 1) as conns"
  >
    <app-connection
      *ngFor="let c of conns; index as i"
      [connection]="c"
      [stepCount]="i"
      [relatedConnection]="i === 0 ? userConnection : conns[i - 1]"
    ></app-connection>
  </div>
</ng-template>

<ng-template #spinner>
  <app-spinner></app-spinner>
</ng-template>
