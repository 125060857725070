<app-title-bar [showMenu]="false" [showGoBack]="true"></app-title-bar>
<div class="menu-container">
  <button
    (click)="switchLocaleHandler()"
    type="button"
    class="btn btn-link bigger-text"
    translate
  >
    menu.locale
  </button>
  <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
  <a
   [href]="'assets/files/tutorial_' + locale + '.pdf'"
    class="btn btn-link bigger-text"
    target="_blank"
    rel="noopener noreferrer"
    translate
    >menu.tutorial</a
  >
  <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
  <a
    href="https://www.geni.com/discussions/228228"
    class="btn btn-link bigger-text"
    target="_blank"
    rel="noopener noreferrer"
    translate
    >menu.help</a
  >
  <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
  <a [routerLink]="[aboutUrl]" class="btn btn-link bigger-text" translate
    >menu.about</a
  >
  <img src="assets/img/lineOfCircles.svg" class="circles-separator" *ngIf="isAuthenticated" />
  <button
    *ngIf="isAuthenticated"
    (click)="resetConnections()"
    [disabled]="isLoading"
    type="button"
    class="btn btn-link bigger-text"
    translate
  >menu.reset</button>
  <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
  <button
    *ngIf="isAuthenticated"
    (click)="logoutHandler()"
    type="button"
    class="btn btn-link bigger-text"
    translate
  >
    menu.logOut
  </button>
</div>
