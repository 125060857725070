<div class="welcome">
  <app-title-bar [showTitle]="false"></app-title-bar>
  <section class="box welcome__top">
      <img
        src="assets/img/ANU-family.svg"
        alt=""
        class="title-logo"
      />
    <h2 class="subtitle-text" [innerHTML]="'welcome.top.headline' | translate"></h2>
    <h4
      class="subtitle-text"
      [ngClass]="{ 'hebrew-direction': isHebrewLocale }"
      translate
    >
      welcome.top.question
    </h4>
    <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
    <p class="regular-text" translate>welcome.top.whatToDo</p>
  </section>
  <section class="welcome__middle">
    <form class="box" (ngSubmit)="loginHandler()" [formGroup]="loginForm">
      <button
        type="submit"
        class="btn btn-link accent-text general-link"
        translate
      >
        welcome.middle.login
      </button>
      <div class="welcome__photos-row" #photosBox>
        <img *ngFor="let src of photos" src="{{ src }}" alt="" />
      </div>
      <p
        class="regular-text"
        [ngClass]="{ 'hebrew-direction': isHebrewLocale }"
        translate
      >
        welcome.middle.instructions
      </p>
      <div
        class="welcome__agree-box"
        [ngClass]="{ 'hebrew-alignment': isHebrewLocale }"
      >
        <input
          type="checkbox"
          autocomplete="off"
          formControlName="agreeToTerms"
          #agreeToTerms
        />
        <span
          [innerHTML]="
            'welcome.middle.terms' | translate: { value: termsOfUseUrl }
          "
        ></span>
      </div>
      <div
        class="welcome__agree-box"
        [ngClass]="{ 'hebrew-alignment': isHebrewLocale }"
      >
        <input
          type="checkbox"
          autocomplete="off"
          formControlName="agreeToConnectRelatives"
          #agreeToConnectRelatives
        />
        <span
          (click)="showModalConnectWithRelatives()"
          [ngClass]="{ 'hebrew-direction': isHebrewLocale }"
          [innerHTML]="'welcome.middle.relatives' | translate"
        ></span>
      </div>
    </form>
  </section>
  <section class="box welcome__bottom">
    <div class="other-platform" >
      <h5 class="accent-text" translate [ngClass]="{ 'hebrew-direction': isHebrewLocale }" >welcome.bottom.anotherPlatform</h5>
      <p class="regular-text" translate [ngClass]="{ 'hebrew-direction': isHebrewLocale }" >welcome.bottom.uploadGedcom</p>
    </div>
    <div class="none-platform">
      <h5 class="accent-text" translate [ngClass]="{ 'hebrew-direction': isHebrewLocale }" >welcome.bottom.noFamilyTree</h5>
      <p class="regular-text" translate [ngClass]="{ 'hebrew-direction': isHebrewLocale }" >welcome.bottom.invitedToDesk</p>
    </div>
    <img src="assets/img/lineOfCircles.svg" class="circles-separator" />
    <img
      class="bottom-logo"
      src="assets/img/Anu_Pos_{{logoHebrewSuffix}}.svg"
      [alt]="'welcome.bottom.logoAlt' | translate"
    />
  </section>
</div>
